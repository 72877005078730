import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Plus from "../images/Plus.svg"
import Img from "gatsby-image/withIEPolyfill"
import BlogItem from "../components/blogItem"
import SEO from "../components/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ogImage from "../images/Frame-7.png"

import "../styles/blog.scss"

function BlogTemplate(props) {
  const filters = {
    latest: props.data.allContentfulBlogs.edges,
    marketing: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("marketing")
    ),
    commerce: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("commerce")
    ),
    culture: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("culture")
    ),
    ourPicks: props.data.allContentfulBlogs.edges.filter(blog =>
      blog.node.tags.includes("ourPicks")
    ),
  }
  const labels = {
    latest: "Latest",
    marketing: "Marketing",
    commerce: "Commerce",
    culture: "Culture",
    ourPicks: "Our Picks",
  }

  const description = {
    latest:
      "Real-time and topical posts on all things commerce, as and when we publish them.",
    marketing:
      "Improve your marketing strategy, get insights on how to drive growth and loyalty, and build high performance stores that convert.",
    commerce:
      "Gain deep insights on the latest e-commerce trends and technologies, and leverage intelligent features to build differentiation.",
    culture:
      "Take a walk with us and meet the faces behind Coderapper. We share our thoughts on our culture, progress, and processes.",
    ourPicks:
      "Okay we get it, you want the good stuff. Here are some handpicked posts from our blog that our readers love!",
  }

  const [activeTab, setActiveTab] = useState("latest")
  const changeTab = (e, active) => {
    e.preventDefault()
    setActiveTab(active)
  }
  return (
    <Layout data={{ headerType: "light" }}>
      <SEO
        title="Blog"
        description=""
        image={`https://coderapper.com${ogImage}`}
        
      />
      <div className="container">
        <section className="blog-header">
          <Link to={`/blog`} ><p className="title-tag">/ Blog</p></Link>
          <h1 className="page-title">
            All things e-commerce, for inspired entrepreneurs.
          </h1>
        </section>

        <section className="feature-banner">
          <div className="feature-banner__content">
            <h4 className="title-tag">/ Featured</h4>
            <Link to={`/blog/${filters.latest[0].node.tags}/${filters.latest[0].node.slug}`}>
              <h2 className="feature-banner__title">
                {filters.latest[0].node.title}
              </h2>
            </Link>
            <p className="feature-banner__description">
              {filters.latest[0].node.seoMetaDescription.seoMetaDescription}
            </p>
            <figure className="article-author">
              {filters.latest[0].node.author.image &&
                filters.latest[0].node.author.image.fluid && (
                  <Img
                    className="article-author__image"
                    fluid={filters.latest[0]?.node.author.image.fluid}
                  />
                )}
              <figcaption className="article-author__details">
                <h5 className="article-author__name">
                  {filters.latest[0]?.node?.author?.name}
                </h5>
                <h6 className="article-author__role">
                  {filters.latest[0]?.node?.author?.title}
                </h6>
              </figcaption>
            </figure>
          </div>
          <div className="feature-banner__image">
            {filters.latest[0].node.heroImage &&
              filters.latest[0].node.heroImage.fluid && (
                <Img
                  className="feature-banner__image_hero"
                  fluid={filters.latest[0]?.node?.heroImage?.fluid}
                />
              )}
          </div>
        </section>
  
        <section>
          <div className="cdr-tab">
            <nav className="cdr-tab__nav">
              <ul className="cdr-tab__list">
                <li>
                  <a
                    className={`cdr-tab__item ${
                      activeTab === "latest" ? "cdr-tab__item--active" : ""
                    }`}
                    href=""
                    onClick={e => {
                      changeTab(e, "latest")
                    }}
                  >
                    Latest
                  </a>
                </li>
                {filters.marketing.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "marketing" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "marketing")
                      }}
                    >
                      Marketing
                    </a>
                  </li>
                )}
                {filters.commerce.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "commerce" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "commerce")
                      }}
                    >
                      Commerce
                    </a>
                  </li>
                )}
                {filters.culture.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "culture" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "culture")
                      }}
                    >
                      Culture
                    </a>
                  </li>
                )}
                {filters.ourPicks.length > 0 && (
                  <li>
                    <a
                      className={`cdr-tab__item ${
                        activeTab === "ourPicks" ? "cdr-tab__item--active" : ""
                      }`}
                      href=""
                      onClick={e => {
                        changeTab(e, "ourPicks")
                      }}
                    >
                      Our Picks
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="cdr-tab__content">
            <div className="cdr-tab__banner">
              <h2 className="cdr-tab__banner-title">{labels[activeTab]}</h2>
              <p className="cdr-tab__banner-description">
                {description[activeTab]}
              </p>
            </div>

            <div className="cdr-tab__content-item">
              {filters[activeTab].map((post, key) => {
                return (
                  <BlogItem
                    key={post.node.id}
                    artitleId={post.node.id}
                    artitleSlug={post.node.slug}
                    artitleTitle={post.node.title}
                    artitleTag={post.node.tags}
                    articleImage={post.node?.heroImage}
                    author={post.node.author?.name}
                    authorTitle={post.node.author?.title}
                    authorImage={post.node.author?.image}
                    otherProps={{ ...post.node }}
                  />
                )
              })}
            </div>
          </div>
        </section>
       
        {/* <div className="btn-more"><a href="#">Load more</a></div> */}
      </div>
    </Layout>
  )
}

export default BlogTemplate
export const pageQuery = graphql`
 query BlogTemplateQuery {
  allContentfulBlogs(
    sort: {fields: publishDate, order: DESC}
    filter: {node_locale: {eq: "en-US"}, featureBlog: {eq: true}}
  ) {
    edges {
      node {
        id
        node_locale
        tags
        title
        slug
        metaDescription
        seoMetaDescription {
          seoMetaDescription
        }
        updatedAt
        createdAt
        heroImage {
          fluid {
            srcSet
            src
          }
        }
        author {
          company
          name
          title
          image {
            fluid {
              srcSet
              src
            }
          }
        }
        description {
          raw
        }
      }
    }
    pageInfo {
      currentPage
      hasNextPage
      hasPreviousPage
      pageCount
      itemCount
      perPage
      totalCount
    }
  }
}
`
